import React from "react";
import HeadingNavigation from "./herosection/HeadingNavigation";
import "../../styles/resource_new.scss";
import Blogs from "../resource/blog/blog";
import Whitepaper from "./whitepaperblog";

const Resource = ({ data, isMobileView, pageData }) => {
  return (
    <div>
      <HeadingNavigation data={data} />
      {data.slug === "white-papers" && (
        <Whitepaper isMobileView={isMobileView} locale={data.locale} />
      )}
      {data.slug === "blog" && (
        <Blogs slug={data.slug} locale={data.locale} pageData={pageData} />
      )}
    </div>
  );
};

export default Resource;
