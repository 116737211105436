import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import BlogImage from "./BlogImage";
import { Link } from "gatsby";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
} from "react-icons/io";

const Blog = ({ locale, pageData }) => {
  const queryData = useStaticQuery(graphql`
    query {
      NoImage: file(relativePath: { eq: "no-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 210) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allIndustries: allStrapiIndustry {
        edges {
          node {
            id
            value
            blogs {
              id
              title
            }
          }
        }
      }
      allTechnologies: allStrapiTechnologyList {
        edges {
          node {
            value
            id
            blogs {
              id
              title
            }
          }
        }
      }
      allServices: allStrapiServiceList {
        edges {
          node {
            value
            id
            blogs {
              id
              title
            }
          }
        }
      }

      allStrapiBlog(sort: { date: ASC }, limit: 500) {
        edges {
          node {
            id
            locale
            slug
            createdAt
            date(formatString: "DD MMMM YYYY")
            time
            title
            heading1
            preview {
              data {
                preview
              }
            }
            technologyList {
              value
              id
            }
            serviceList {
              value
              id
            }
            industryList {
              value
              id
            }
            id
            Author {
              name
            }
            blogimg {
              ext
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 210) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            detailDescription {
              data {
                detailDescription
              }
            }
          }
        }
      }
    }
  `);
  const data = queryData.allStrapiBlog.edges;
  const allIndustries = queryData.allIndustries.edges.filter(
    (item) => !!item.node.value
  );
  const allTechnologies = queryData.allTechnologies.edges.filter(
    (item) => !!item.node.value
  );
  const duplicatedServices = queryData.allServices.edges.filter(
    (item) => !!item.node.value
  );
  const allServices = [];
  duplicatedServices.forEach((service) => {
    if (!allServices.find((item) => item.node.value === service.node.value)) {
      allServices.push(service);
    }
  });
  const allBlogs = useMemo(
    () => data.filter((node) => node.node.locale === locale),
    [data, locale]
  );

  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedSubOption, setSelectedSubOption] = useState([]);
  const [showLists, setShowLists] = useState([]);
  const [selectedBlogs, setSelectedBlogs] = useState(allBlogs);
  const handleChangeMainCategory = useCallback(
    (category) => {
      if (selectedOption.includes(category)) {
        setSelectedOption(
          selectedOption.filter((option) => option !== category)
        );
        let updatedSubOption = selectedSubOption;
        let blogs = [];
        if (category === "Industry") {
          allIndustries.forEach((item) => {
            updatedSubOption = updatedSubOption.filter((ele) => ele !== item);
          });
        }
        if (category === "Technology") {
          allTechnologies.forEach((item) => {
            updatedSubOption = updatedSubOption.filter((ele) => ele !== item);
          });
        }
        if (category === "Service") {
          allServices.forEach((item) => {
            updatedSubOption = updatedSubOption.filter((ele) => ele !== item);
          });
        }
        setSelectedSubOption(updatedSubOption);
        if (updatedSubOption.length === 0) {
          blogs = allBlogs;
        } else {
          blogs = allBlogs.filter((blog) =>
            updatedSubOption.find(
              (option) => option.node.value === blog.node.title
            )
          );
        }
        setSelectedBlogs(blogs);
      } else {
        setSelectedOption([...selectedOption, category]);
        let subs = [];
        if (category === "Industry") {
          subs = allIndustries;
        }
        if (category === "Technology") {
          subs = allTechnologies;
        }
        if (category === "Service") {
          subs = allServices;
        }
        setSelectedSubOption([...selectedSubOption, ...subs]);
        const blogs = allBlogs.filter((blog) =>
          [...selectedSubOption, ...subs].find(
            (option) => option.node.value === blog.node.title
          )
        );
        setSelectedBlogs(blogs);
      }
    },
    [selectedOption, selectedSubOption, allBlogs]
  );

  const handleChangeSubCategory = useCallback(
    (subCategory) => {
      let updatedSubOption = [];
      if (selectedSubOption.includes(subCategory)) {
        updatedSubOption = selectedSubOption.filter(
          (option) => option !== subCategory
        );
      } else {
        updatedSubOption = [...selectedSubOption, subCategory];
      }

      setSelectedSubOption(updatedSubOption);
      if (updatedSubOption.length === 0) {
        setSelectedBlogs(allBlogs);
      } else {
        setSelectedBlogs(
          allBlogs.filter((blog) =>
            updatedSubOption.find(
              (option) => option.node.value === blog.node.title
            )
          )
        );
      }
    },
    [selectedSubOption, allBlogs]
  );

  return (
    <div className="blogPageContainer">
      <div className="categoryContainer">
        <h3>{pageData.categories}</h3>
        <ul className="categoryContent">
          <li>
            <div className="categoryItem">
              {showLists.includes("Industry") ? (
                <IoIosArrowDropdownCircle
                  size={20}
                  onClick={() =>
                    setShowLists(
                      showLists.filter((list) => list !== "Industry")
                    )
                  }
                />
              ) : (
                <IoIosArrowDropupCircle
                  size={20}
                  onClick={() => setShowLists([...showLists, "Industry"])}
                />
              )}
              <input
                type="checkbox"
                name="Industry"
                checked={selectedOption.includes("Industry")}
                onChange={() => handleChangeMainCategory("Industry")}
              />
              <a href="#">{pageData.industry}</a>
            </div>
            <ul
              className={
                showLists.includes("Industry")
                  ? "subContainer"
                  : "subContainerHidden"
              }
            >
              {allIndustries.map((item) => (
                <div className="categoryItem" key={item.node.value}>
                  <input
                    type="checkbox"
                    name={item.node.value}
                    checked={selectedSubOption.includes(item)}
                    onChange={() => handleChangeSubCategory(item)}
                  />
                  <a href="#">{item.node.value}</a>
                </div>
              ))}
            </ul>
          </li>
          <li>
            <div className="categoryItem">
              {showLists.includes("Technology") ? (
                <IoIosArrowDropdownCircle
                  size={20}
                  onClick={() =>
                    setShowLists(
                      showLists.filter((list) => list !== "Technology")
                    )
                  }
                />
              ) : (
                <IoIosArrowDropupCircle
                  size={20}
                  onClick={() => setShowLists([...showLists, "Technology"])}
                />
              )}
              <input
                type="checkbox"
                name="Technology"
                checked={selectedOption.includes("Technology")}
                onChange={() => handleChangeMainCategory("Technology")}
              />
              <a href="#">{pageData.technology}</a>
            </div>
            <ul
              className={
                showLists.includes("Technology")
                  ? "subContainer"
                  : "subContainerHidden"
              }
            >
              {allTechnologies.map((item) => (
                <div className="categoryItem" key={item.node.value}>
                  <input
                    type="checkbox"
                    name={item.node.value}
                    checked={selectedSubOption.includes(item)}
                    onChange={() => handleChangeSubCategory(item)}
                  />
                  <a href="#">{item.node.value}</a>
                </div>
              ))}
            </ul>
          </li>
          <li>
            <div className="categoryItem">
              {showLists.includes("Service") ? (
                <IoIosArrowDropdownCircle
                  size={20}
                  onClick={() =>
                    setShowLists(showLists.filter((list) => list !== "Service"))
                  }
                />
              ) : (
                <IoIosArrowDropupCircle
                  size={20}
                  onClick={() => setShowLists([...showLists, "Service"])}
                />
              )}
              <input
                type="checkbox"
                name="Technology"
                checked={selectedOption.includes("Service")}
                onChange={() => handleChangeMainCategory("Service")}
              />
              <a href="#">{pageData.service}</a>
            </div>
            <ul
              className={
                showLists.includes("Service")
                  ? "subContainer"
                  : "subContainerHidden"
              }
            >
              {allServices.map((item) => (
                <div className="categoryItem" key={item.node.value}>
                  <input
                    type="checkbox"
                    name={item.node.value}
                    checked={selectedSubOption.includes(item)}
                    onChange={() => handleChangeSubCategory(item)}
                  />
                  <a href="#">{item.node.value}</a>
                </div>
              ))}
            </ul>
          </li>
        </ul>
      </div>
      <div className="blogListContainer">
        {selectedBlogs && selectedBlogs.length > 0 ? (
          selectedBlogs.map(({ node }) => (
            <Link
              to={`/resource/blog/${node.slug}`}
              className="blogItem"
              key={node.id}
            >
              <BlogImage data={node} />
              <h3>{node.heading1}</h3>
            </Link>
          ))
        ) : (
          <p>{pageData.noPost}</p>
        )}
      </div>
      <div className="mobileCategoryContainer">
        <h3>{pageData.categories}</h3>
        <ul className="categoryContent">
          <li>
            <div className="categoryItem">
              {showLists.includes("Industry") ? (
                <IoIosArrowDropdownCircle
                  size={20}
                  onClick={() =>
                    setShowLists(
                      showLists.filter((list) => list !== "Industry")
                    )
                  }
                />
              ) : (
                <IoIosArrowDropupCircle
                  size={20}
                  onClick={() => setShowLists([...showLists, "Industry"])}
                />
              )}
              <input
                type="checkbox"
                name="Industry"
                checked={selectedOption.includes("Industry")}
                onChange={() => handleChangeMainCategory("Industry")}
              />
              <a href="#">{pageData.industry}</a>
            </div>
            <ul
              className={
                showLists.includes("Industry")
                  ? "subContainer"
                  : "subContainerHidden"
              }
            >
              {allIndustries.map((item) => (
                <div className="categoryItem" key={item.node.value}>
                  <input
                    type="checkbox"
                    name={item.node.value}
                    checked={selectedSubOption.includes(item)}
                    onChange={() => handleChangeSubCategory(item)}
                  />
                  <a href="#">{item.node.value}</a>
                </div>
              ))}
            </ul>
          </li>
          <li>
            <div className="categoryItem">
              {showLists.includes("Technology") ? (
                <IoIosArrowDropdownCircle
                  size={20}
                  onClick={() =>
                    setShowLists(
                      showLists.filter((list) => list !== "Technology")
                    )
                  }
                />
              ) : (
                <IoIosArrowDropupCircle
                  size={20}
                  onClick={() => setShowLists([...showLists, "Technology"])}
                />
              )}
              <input
                type="checkbox"
                name="Technology"
                checked={selectedOption.includes("Technology")}
                onChange={() => handleChangeMainCategory("Technology")}
              />
              <a href="#">{pageData.technology}</a>
            </div>
            <ul
              className={
                showLists.includes("Technology")
                  ? "subContainer"
                  : "subContainerHidden"
              }
            >
              {allTechnologies.map((item) => (
                <div className="categoryItem" key={item.node.value}>
                  <input
                    type="checkbox"
                    name={item.node.value}
                    checked={selectedSubOption.includes(item)}
                    onChange={() => handleChangeSubCategory(item)}
                  />
                  <a href="#">{item.node.value}</a>
                </div>
              ))}
            </ul>
          </li>
          <li>
            <div className="categoryItem">
              {showLists.includes("Service") ? (
                <IoIosArrowDropdownCircle
                  size={20}
                  onClick={() =>
                    setShowLists(showLists.filter((list) => list !== "Service"))
                  }
                />
              ) : (
                <IoIosArrowDropupCircle
                  size={20}
                  onClick={() => setShowLists([...showLists, "Service"])}
                />
              )}
              <input
                type="checkbox"
                name="Technology"
                checked={selectedOption.includes("Service")}
                onChange={() => handleChangeMainCategory("Service")}
              />
              <a href="#">{pageData.service}</a>
            </div>
            <ul
              className={
                showLists.includes("Service")
                  ? "subContainer"
                  : "subContainerHidden"
              }
            >
              {allServices.map((item) => (
                <div className="categoryItem" key={item.node.value}>
                  <input
                    type="checkbox"
                    name={item.node.value}
                    checked={selectedSubOption.includes(item)}
                    onChange={() => handleChangeSubCategory(item)}
                  />
                  <a href="#">{item.node.value}</a>
                </div>
              ))}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Blog;
