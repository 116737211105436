import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Moment from "react-moment";
import { useStaticQuery, graphql } from "gatsby";
import WhitePaperModal from "./whitepapersModal";
import ReactMarkdown from "react-markdown";
import Img from "gatsby-image";

function Whitepaper({ locale, isMobileView }) {
  const [showModal, setShowModal] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      allStrapiWhitepaperModal {
        edges {
          node {
            locale
            preview {
              title
              heading
              btntext
            }
            previewDescription {
              data {
                previewDescription
              }
            }
            previewImg {
              url
            }
          }
        }
      }
    }
  `).allStrapiWhitepaperModal.edges;
  const getData = data.filter((node) => node.node.locale === locale);

  return (
    <>
      <div className="psection3 whitepaperPageContainer">
        <div className="psection3Cont d-flex">
          {!isMobileView && (
            <img
              alt="psection3"
              src={getData[0].node.previewImg[0].url}
              height={432}
              width={368}
            />
          )}

          <div className="psection3Abt whitepaperPageBlock col-8">
            <div className="psection3AbtCont">
              <div className="d-flex">
                <div className="col-md-9 col-xs-12">
                  <div className="psection3AbtTitle">
                    {getData[0].node.preview?.title}
                  </div>
                  <div className="psection3AbtHeading">
                    {getData[0].node.preview?.heading}
                  </div>
                </div>

                <div
                  className={`opsBtnwrap mt-3 ${
                    isMobileView ? "col-5" : "col-3"
                  }`}
                  onClick={() => setShowModal(true)}
                >
                  <a className="opsBtnlink">
                    <p>{getData[0].node.preview?.btntext}</p>
                  </a>
                </div>
              </div>

              <div className="psection3AbtDes">
                <ReactMarkdown>
                  {getData[0].node.previewDescription.data.previewDescription}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <WhitePaperModal
          visible={showModal}
          handleClose={() => setShowModal(false)}
          locale={locale}
        />
      )}

      <div className="whitePaper_blank"></div>
    </>
  );
}

export default Whitepaper;
