import React, { useState, useEffect } from "react";
import Layout from "../components/layoutnew";
import Resource from "../components/resource";
import { graphql } from "gatsby";
import SEO from "../components/seo";

function MainResourcePage({ data }) {
  const getData = data.allStrapiResourceMainSection.edges[0].node;
  const getPageData = data.allStrapiBlogPage.edges[0].node;
  const slug = `resource/${getData.slug}`;
  const [isMobileView, setIsMobileView] = useState(false);
  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768); // Change 768 as per your breakpoint
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  return (
    <Layout slug={slug} locale={getData.locale} isMobileView={isMobileView}>
      <SEO
        noindexForce={false}
        slug={slug}
        locale={getData.locale}
        title={getData.metaTitle}
        description={getData.metaDes}
      />
      <Resource
        data={getData}
        isMobileView={isMobileView}
        pageData={getPageData.blogList}
      />
    </Layout>
  );
}

export default MainResourcePage;
export const query = graphql`
  query MainResourcePage($slug: String!, $locale: String!) {
    allStrapiBlogPage(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          locale
          blogList {
            noPost
            service
            industry
            categories
            technology
          }
        }
      }
    }
    allStrapiResourceMainSection(
      filter: { slug: { eq: $slug }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          slug
          locale
          heading
          menubar {
            l1
            l2
            l3
            l4
          }
          blogDropDown {
            all
            byDate
            industry
            popularBlogs
            technology
            trending
          }
          heading2
          title2
          title
          metaTitle
          metaDes
        }
      }
    }
  }
`;
